import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Blog App Template | Blog App UI Design | Uizard"
    canonical="/templates/mobile-app-templates/food-blog-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/blog-tablet-app/"
    metaDescription="Our beautifully designed blog app template has everything you need to quickly design a professional-looking tablet blog app. Sign up now."
    description="
    h2:A blog app UI design template for tablet
    <br/>
    Our beautifully designed blog app template has everything you need to quickly design a professional-looking tablet blog app. Whether you're an individual blogger looking to create your own platform or a business looking to reach your customers through an engaging and interactive tablet app, this brand-new <a:https://uizard.io/templates/>UI template</a> has got you covered.
    <br/>
    h3:Speed up your design process with Uizard
    <br/>
    <a:https://uizard.io/ux-design/>UX design</a> has never been easier or faster. With our intuitive drag-and-drop editor and wide range of pre-made UI elements, you can create stunning mockups of your app in minutes. And with the power of Uizard's real-time collaboration features, you can share your design with your team or stakeholders and get feedback instantly.
    <br/>
    h3:Customize to fit your brand vision
    <br/>
    With all our templates, you have complete control over the look and feel of your app. Change the color scheme to match your brand, add custom graphics and images, and tweak the layout to fit your needs. With Uizard, you can easily create a unique and personalized blog app that sets you apart from the competition. Sign up for Uizard Pro and let the <a:https://uizard.io/templates/mobile-app-templates/>app design</a> magic begin!
    "
    pages={[
      "Blog app landing page mockup with all the core UX and navigation features",
      "Article landing page with core information segments and author info mockup",
      "Category landing page with article feed",
      "Newsletter sign-up example screen"
    ]}
    projectCode="bMy8MwOY4zHJamovvZ7j"
    img1={data.image1.childImageSharp}
    img1alt="food blog tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="food blog tablet app design article landing page screen"
    img3={data.image3.childImageSharp}
    img3alt="food blog tablet app design category landing page screen"
    img4={data.image4.childImageSharp}
    img4alt="food blog tablet app design signup screen"
    img5={data.image5.childImageSharp}
    img5alt="food blog tablet app summary screens"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/blog-website/food-blog-tablet-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/blog-website/food-blog-tablet-article.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/blog-website/food-blog-tablet-category.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/blog-website/food-blog-tablet-newsletter.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/blog-website/food-blog-tablet-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
